import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
       <h1>Portfolio</h1>
      
      </header>
    </div>
  );
}

export default App;
